<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Register v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <vuexy-logo />
          <h2 class="brand-text text-primary ml-1">
            WhatsBot
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          A aventura vai começar 🚀
        </b-card-title>
        <b-card-text class="mb-2">
          Voce está a poucos passos de ter um bot topzera no seu grupo!
        </b-card-text>

        <!-- form -->
        <validation-observer ref="registerForm">
          <b-form
            class="auth-register-form mt-2"
            @submit.prevent="validationForm"
          >
            <!-- name -->
            <b-form-group
              label="Nome"
              label-for="name"
            >
              <validation-provider
                #default="{ errors }"
                name="Nome"
                rules="required"
              >
                <b-form-input
                  id="name"
                  v-model="name"
                  :state="errors.length > 0 ? false:null"
                  name="register-username"
                  placeholder="nome"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- username -->
            <b-form-group
              label="Usuario"
              label-for="username"
            >
              <validation-provider
                #default="{ errors }"
                name="Usuario"
                rules="required"
              >
                <b-form-input
                  id="username"
                  v-model="username"
                  :state="errors.length > 0 ? false:null"
                  name="register-username"
                  placeholder="usuario"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- phone -->
            <b-form-group
              label="Celular"
              label-for="phone"
            >
              <validation-provider
                #default="{ errors }"
                name="Celular"
                rules="required"
              >
                <b-form-input
                  id="phone"
                  v-model="phone"
                  :state="errors.length > 0 ? false:null"
                  name="register-phone"
                  placeholder="(21) 93274-2910"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group
              label="Senha"
              label-for="password"
            >
              <validation-provider
                #default="{ errors }"
                name="Senha"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="register-password"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- vendedor -->
            <b-form-group
              label="Vendedor"
              label-for="saller"
            >
              <validation-provider
                #default="{ errors }"
                name="Vendedor"
                rules="required"
              >
                <b-form-select
                  v-model="selected"
                  :options="options"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              block
              type="submit"
            >
              CADASTRAR
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <span>Já tem uma conta? </span>
          <b-link :to="{name:'login'}">
            <span>ENTRAR</span>
          </b-link>
        </b-card-text>

      </b-card>
    <!-- /Register v1 -->
    </div>
  </div>

</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BLink, BCardTitle, BCardText, BForm, BFormSelect,
  BButton, BFormInput, BFormGroup, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import funcs from '@/services/func.service'

export default {
  components: {
    VuexyLogo,
    // BSV
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      name: '',
      username: '',
      password: '',
      phone: '',
      status: '',

      // validation rules
      required,

      // select
      selected: 2,
      options: [
        { value: 2, text: 'Selecione o Vendedor' },
        { value: 2, text: 'Lucas' },
        { value: 3, text: 'Lucas' },
        { value: 4, text: 'Hera' },
      ],
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  mounted() {
    this.fetchResellers()
  },
  methods: {
    validationForm() {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          const payload = {
            reseller_id: this.selected, name: this.name, username: this.username, password: this.password, phone: this.phone,
          }
          this.$store.dispatch('auth/register', payload).then(response => {
            console.log(response)
            if (response.token) {
              this.$router
                .push({ name: 'login' })
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Bem vindo!!',
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: 'Você se cadastrou com sucesso, faça o login!!',
                    },
                  })
                })
                .catch(error => {
                  console.log(error)
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Opa!!',
                      icon: 'CoffeeIcon',
                      variant: 'info',
                      text: 'Conta criada, porém nao consegui te redirecionar, ATUALIZE A PAGINA E TENTE SE CONECTAR.!',
                    },
                  })
                })
            }

            if (response.error) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Erro',
                  icon: 'BellIcon',
                  text: '😧 Problema ao criar sua conta, verifique os campos.',
                  variant: 'danger',
                },
              })
            }
          })
        }
      }).catch(error => {
        console.log(error)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Erro',
            icon: 'BellIcon',
            text: '😧 Erro ao se conectar no servidor, contate o administrador.',
            variant: 'danger',
          },
        })
      })
    },

    fetchResellers() {
      funcs.fetchResellers().then(success => {
        if (success.data.resellers) {
          this.options = success.data.resellers.map(resellers => {
            const optionsNew = {}
            optionsNew.value = resellers.id
            optionsNew.text = resellers.username
            this.options = optionsNew
            return optionsNew
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
